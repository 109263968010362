import React from "react"
import { Box } from "theme-ui"
import { useResponsiveValue } from "@theme-ui/match-media"
import Img from "gatsby-image"
import styled from "@emotion/styled"

const FullImagePanel = ({ mobile, desktop, ...rest }) => {
  const imageSize = useResponsiveValue([mobile, mobile, desktop])

  return (
    <Container {...rest}>
      <Img fluid={imageSize} />
    </Container>
  )
}

export default FullImagePanel

const Container = styled(Box)`
  .gatsby-image-wrapper {
    height: 100%;
  }
`
