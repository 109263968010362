/** @jsx jsx */
import { jsx, Box, Grid, Link } from "theme-ui"
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby"
import Image from "gatsby-image"
import styled from "@emotion/styled"

import helloBadge from "../images/hello-badge.png"
import episodeLogo from "../images/panel_01-episode.svg"
import episodeCharacters from "../images/panel_01-episode-characters.svg"
import FullImage from "../components/home/FullImage"
import Layout from "../components/Layout"

const StoryPage = () => {
  const data = useStaticQuery(graphql`
    query {
      episodeOne: file(relativePath: { eq: "home/panel_02-img-02.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      episodeTwo: file(relativePath: { eq: "home/panel_02-img-01.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      episodeThree: file(relativePath: { eq: "home/panel_02-img-03.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      panelSevenImage: file(
        relativePath: { eq: "home/panel_07-full-img.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      panelSevenImageMobile: file(
        relativePath: { eq: "home/panel_07-full-img-m.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Box bg="#020202">
        <Box sx={{ maxWidth: 1200, py: [50, 100], px: 50, mx: "auto" }}>
          <EpisodeLogo src={episodeLogo} />
          <EpisodeCharacters src={episodeCharacters} />
        </Box>
        <Box sx={{ mb: 200 }}>
          <EpisodePanel>
            <Box sx={{ gridColumn: "col-start 2 / span 10" }}>
              <Grid
                sx={{
                  gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
                  gridGap: "35px",
                }}
              >
                <Box
                  sx={{
                    borderRadius: 20,
                    overflow: "hidden",
                  }}
                >
                  <Link as={GatsbyLink} to="/episode-002">
                    <Image fluid={data.episodeTwo.childImageSharp.fluid} />
                  </Link>
                </Box>
                <Box
                  sx={{
                    borderRadius: 20,
                    overflow: "hidden",
                  }}
                >
                  <Link as={GatsbyLink} to="/episode-001">
                    <Image fluid={data.episodeOne.childImageSharp.fluid} />
                  </Link>
                </Box>
                <Box
                  sx={{
                    borderRadius: 20,
                    overflow: "hidden",
                  }}
                >
                  <Image fluid={data.episodeThree.childImageSharp.fluid} />
                </Box>
              </Grid>
            </Box>
          </EpisodePanel>
        </Box>

        <Box sx={{ position: "relative" }}>
          <FullImage
            desktop={data.panelSevenImage.childImageSharp.fluid}
            mobile={data.panelSevenImageMobile.childImageSharp.fluid}
            sx={{ maxHeight: 400, overflow: "hidden" }}
          />
          <Box
            as="img"
            sx={{
              position: "absolute",
              top: 0,
              left: "50%",
              width: ["40vw", "25vw"],
              maxWidth: 320,
              transform: "translate(-50%, -50%)",
            }}
            src={helloBadge}
            alt=""
          />
        </Box>
      </Box>
    </Layout>
  )
}

const EpisodeLogo = styled(`img`)`
  margin-bottom: 20px;
  width: 100%;

  @media (min-width: 1000px) {
    padding: 0 10%;
  }
`

const EpisodeCharacters = styled(`img`)`
  width: 100%;

  @media (min-width: 1000px) {
    padding: 0 20%;
  }
`

const EpisodePanel = styled("div")`
  color: white;
  padding: 50px 40px 50px;
  max-width: 1200px;

  @media (min-width: 750px) {
    margin: 0 auto;
    padding: 100px 40px 0;
    display: grid;
    grid-gap: 35px;
    grid-template-columns: repeat(12, [col-start] 1fr);
  }
`

export default StoryPage
